import React from "react"

import Layout from "../components/layout/layout"
import Seo from "../components/seo"
import Collection from "../components/collection/collection"
import MoreCollections from "../components/moreCollections"

const CollectionPage = ({ pageContext }) => {
  let { moreCollections, isShop, collection } = pageContext

  return (
    <Layout
      isShopRoute={isShop}
      isCollectionsRoute={!isShop}
      pageTitle={collection.title}
      pageTitleDescription={collection.description}
    >
      <Seo
        title={collection.title}
        keywords={[
          collection.title,
          `fashion collection`,
          `slow fashion`,
          `fashion`,
        ]}
        pathname={`/${isShop ? `shop` : `collection`}/${collection.uid}`}
      />

      <Collection collection={collection} isShop={isShop} />

      {moreCollections?.length &&
        <MoreCollections
          allCollections={moreCollections}
          without={collection.uid}
        />
      }
    </Layout>
  )
}

export default CollectionPage
