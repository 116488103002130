import React from "react"

import ShopButton from "../shop/shopButton"
import ItemImage from "../layout/itemImage"
import * as ItemStyles from "./../../styles/item.module.css"
import getItemImagesFromPrismicImageItems from "../../utils/getItemImagesFromPrismicImageItems"
import AniLink from "gatsby-plugin-transition-link/AniLink"

const CollectionItem = ({ item }) => {
  const collectionItemImages = getItemImagesFromPrismicImageItems(item.imageItems, "collection")

  const collectionImages = collectionItemImages.map((imageItem, index) => (
    <ItemImage
      key={`image-${index}`}
      imageWidth={collectionItemImages.length === 1 ? '100%' : null}
      image={imageItem}
    />
  ));

  const imagesContainer = (
    <div className={ItemStyles.itemImageContainer}>
      {item.sold_out && <div className={ItemStyles.soldOutBanner}><span>SOLD OUT</span></div>}
      {collectionImages}
    </div>
  )

  return (
    <div className={[
      ItemStyles.item,
      collectionItemImages.length === 1 ? ItemStyles.halfScreenWidth : ''
    ].join(' ')}>
      {/* Removed items title in PLPs as per Irene's request */}
      {/* <h2 className={ItemStyles.itemTitle}>
        {item.title + " - " + item.collection.document.data.title}
      </h2> */}
      {item.shopLink
        ? <>
          <AniLink
            fade
            to={item.shopLink}
            aria-label={`Go to ${item.title} details page`}
          >{imagesContainer}</AniLink>

          <div className={ItemStyles.shopButtonWrapper}>
            <ShopButton to={item.shopLink} />
          </div>
        </>
        : imagesContainer
      }
    </div>
  )
}

export default CollectionItem
