import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import * as MoreCollectionsStyles from "./moreCollections.module.css"
import AniLink from "gatsby-plugin-transition-link/AniLink"

const MoreCollections = ({ allCollections, without }) => {
  return (
    <StaticQuery
      query={graphql`
        query moreCollectionsThumbnails {
          allFile(
            filter: {
              relativeDirectory: { regex: "/more-collections-thumbnails/" }
            }
          ) {
            edges {
              node {
                name
                childImageSharp {
                  fixed(width: 150, height: 150, quality: 70) {
                    ...GatsbyImageSharpFixed_withWebp
                  }
                }
              }
            }
          }
        }
      `}
      render={data => {
        let images = data.allFile.edges
        let collectionLinks = allCollections.map(collection => {
          let image = images.filter(
            image =>
              image.node.name.toLowerCase() === collection.id.toLowerCase()
          )[0]
          let collectionID = collection.id.toLowerCase()
          if (collectionID === without.toLowerCase() || !image) {
            return ""
          }
          return (
            <div
              className={MoreCollectionsStyles.collection}
              key={collectionID}
            >
              <AniLink
                className={MoreCollectionsStyles.collectionLink}
                to={collection.to}
                fade
              >
                {collection.title}
              </AniLink>
              <Img
                fixed={image.node.childImageSharp.fixed}
                style={{
                  display: "inline-block",
                  zIndex: -1,
                }}
              />
            </div>
          )
        })
        return (
          <div className={MoreCollectionsStyles.moreCollections}>
            {collectionLinks}
          </div>
        )
      }}
    />
  )
}

export default MoreCollections
