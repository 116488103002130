import React from "react"

import * as CollectionStyles from "./collection.module.css"
import CollectionItem from "./item"
import usePreviewData from "../../utils/usePreviewData"
import getItemImagesFromPrismicImageItems from "../../utils/getItemImagesFromPrismicImageItems"

const Collection = ({ collection: staticCollection }) => {
  const collection = usePreviewData(staticCollection);

  if (!collection.items?.length) {
    // * do not display the collection component if there are no items to show
    // * show a coming soon banner instead
    const comingSoonFallback = (
      <div className={CollectionStyles.comingSoonWrapper}>
        <h2 className={CollectionStyles.comingSoonTitle}><span>{collection.title}</span> collection is coming soon...</h2>
      </div>
    )
    return <div className={CollectionStyles.collection}>{comingSoonFallback}</div>
  }

  // * Filter out items that do not have any collection images
  const collectionItemsFiltered = collection.items.filter(item => {
    const itemCollectionImages = getItemImagesFromPrismicImageItems(item.imageItems, 'collection')
    return (itemCollectionImages && itemCollectionImages.length)
  })

  // ? isShop prop can be passed onto this component. Can be used to display prices if the item is for sale
  const collectionItems = collectionItemsFiltered.map(item => <CollectionItem key={item.uid} item={item} />)

  return <div className={CollectionStyles.collection}>{collectionItems}</div>
}

export default Collection
